import { css } from '@emotion/core';

import base, { palette, media, Vars } from './theme';

export { columns, palette, media, Vars } from './theme';
export const themes = { base };

export default css`
  /* - - - VARIABLES - - - */
  :root {
    --main-font: "Source Sans Pro";
    --heading-font: "Rubik";
    --code-font: "Fira Mono";
    --font-size-title: ${base.fontSizeSmall[6]}rem;
    --line-height-title: ${base.lineHeightSmall[6]}rem;
    --font-size-h1: ${base.fontSizeSmall[5]}rem;
    --line-height-h1: ${base.lineHeightSmall[5]}rem;
    --font-size-h2: ${base.fontSizeSmall[4]}rem;
    --line-height-h2: ${base.lineHeightSmall[4]}rem;
    --font-size-h3: ${base.fontSizeSmall[3]}rem;
    --line-height-h3: ${base.lineHeightSmall[3]}rem;
    --font-size-h4: ${base.fontSizeSmall[2]}rem;
    --line-height-h4: ${base.lineHeightSmall[2]}rem;
    --font-size-body: ${base.fontSizeSmall[1]}rem;
    --line-height-body: ${base.lineHeightSmall[1]}rem;
    --font-size-information: ${base.fontSizeSmall[0]}rem;
    --line-height-information: ${base.lineHeightSmall[0]}rem;
    --breakpoint-small: ${base.breakpointSmall}px;
    --breakpoint-medium: ${base.breakpointMedium}px;
    --breakpoint-large: ${base.breakpointLarge}px;
    --breakpoint-full: ${base.breakpointFull}px;
    --current-width: ${base.breakpointSmall}px;
    --border-radius: ${base.borderRadius}rem;
    ${palette('base')};

    ${media.medium} {
      --font-size-title: ${base.fontSizeMedium[6]}rem;
      --line-height-title: ${base.lineHeightMedium[6]}rem;
      --font-size-h1: ${base.fontSizeMedium[5]}rem;
      --line-height-h1: ${base.lineHeightMedium[5]}rem;
      --font-size-h2: ${base.fontSizeMedium[4]}rem;
      --line-height-h2: ${base.lineHeightMedium[4]}rem;
      --font-size-h3: ${base.fontSizeMedium[3]}rem;
      --line-height-h3: ${base.lineHeightMedium[3]}rem;
      --font-size-h4: ${base.fontSizeMedium[2]}rem;
      --line-height-h4: ${base.lineHeightMedium[2]}rem;
      --font-size-body: ${base.fontSizeMedium[1]}rem;
      --line-height-body: ${base.lineHeightMedium[1]}rem;
      --font-size-information: ${base.fontSizeMedium[0]}rem;
      --line-height-information: ${base.lineHeightMedium[0]}rem;
      --current-width: ${base.breakpointMedium}px;
    }

    ${media.large} {
      --font-size-title: ${base.fontSizeLarge[6]}rem;
      --line-height-title: ${base.lineHeightLarge[6]}rem;
      --font-size-h1: ${base.fontSizeLarge[5]}rem;
      --line-height-h1: ${base.lineHeightLarge[5]}rem;
      --font-size-h2: ${base.fontSizeLarge[4]}rem;
      --line-height-h2: ${base.lineHeightLarge[4]}rem;
      --font-size-h3: ${base.fontSizeLarge[3]}rem;
      --line-height-h3: ${base.lineHeightLarge[3]}rem;
      --font-size-h4: ${base.fontSizeLarge[2]}rem;
      --line-height-h4: ${base.lineHeightLarge[2]}rem;
      --font-size-body: ${base.fontSizeLarge[1]}rem;
      --line-height-body: ${base.lineHeightLarge[1]}rem;
      --font-size-information: ${base.fontSizeLarge[0]}rem;
      --line-height-information: ${base.lineHeightLarge[0]}rem;
      --current-width: ${base.breakpointLarge}px;
    }

    ${media.full} {
      --current-width: ${base.breakpointFull}px;
    }
  }

  /* - - - FONTS - - - */

  @font-face {
    font-family: "Source Sans Pro";
    src:
      url("${base.regularWoff2}") format("woff2"),
      url("${base.regularWoff}") format("woff");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Source Sans Pro";
    src:
      url("${base.boldWoff2}") format("woff2"),
      url("${base.boldWoff}") format("woff");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "Source Sans Pro";
    src:
      url("${base.blackWoff2}") format("woff2"),
      url("${base.blackWoff}") format("woff");
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: "Rubik";
    src:
      url("${base.headingRegularWoff2}") format("woff2"),
      url("${base.headingRegularWoff}") format("woff");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Rubik";
    src:
      url("${base.headingBoldWoff2}") format("woff2"),
      url("${base.headingBoldWoff}") format("woff");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "Fira Mono";
    src:
      url("${base.monoRegularWoff}") format("woff2"),
      url("${base.monoRegularWoff2}") format("woff");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Fira Mono";
    src:
      url("${base.monoBoldWoff}") format("woff2"),
      url("${base.monoBoldWoff2}") format("woff");
    font-weight: 700;
    font-style: normal;
  }

  /* - - - RESET - - - */

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-style: normal;
  }
  em { font-style: oblique; }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote::before,
  blockquote::after,
  q::before,
  q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  button,
  input,
  textarea,
  progress {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
  }

  /* - - - LAYOUT - - - */

  html,
  body,
  #___gatsby,
  #root {
    height: 100%;
  }

  html,
  body,
  #___gatsby,
  #root,
  #gatsby-focus-wrapper,
  #router {
    min-height: 100%;
  }

  #root {
    position: relative;
    z-index: 1;
  }

  #gatsby-focus-wrapper,
  #router {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  /* - - - TYPOGRAPHY - - - */

  :root {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ::selection {
    background-color: ${base.brandPrimary};
  }

  body,
  table,
  button,
  input,
  textarea {
    font-family: ${Vars.MainFont};
    font-size: ${Vars.FontSizeBody};
    line-height: ${Vars.LineHeightBody};
    color: ${Vars.TextColor};
  }

  code {
    font-family: ${Vars.CodeFont};
    font-size: 0.9em;
  }

  h1 {
    font-family: ${Vars.HeadingFont};
    font-size: ${Vars.FontSizeH1};
    line-height: ${Vars.LineHeightH1};
    font-weight: 900;
    margin-bottom: calc(${Vars.LineHeightBody} / 2);
  }

  h2 {
    font-family: ${Vars.HeadingFont};
    font-size: ${Vars.FontSizeH2};
    line-height: ${Vars.LineHeightH2};
    font-weight: 700;
    margin-bottom: calc(${Vars.LineHeightBody} / 2);
  }

  h3 {
    font-size: ${Vars.FontSizeH3};
    line-height: ${Vars.LineHeightH3};
    font-weight: 700;
    margin-bottom: calc(${Vars.LineHeightBody} / 2);
  }

  h4 {
    font-size: ${Vars.FontSizeH4};
    line-height: ${Vars.LineHeightH4};
    font-weight: 700;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    color: ${Vars.TextColor};
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: calc(${Vars.LineHeightBody} / 2);

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol, ul {
    padding-left: 4rem;
    padding-bottom: 2rem;
  }

  ol.alpha {
    list-style-type: lower-alpha;
  }

  /* - - - MISC - - - */

  #gatsby-noscript {
    display: none;
  }
`;

export const focus = css`
  position: relative;

  &:focus {
    outline: 0;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: -0.2rem;
      bottom: -0.2rem;
      left: -0.2rem;
      right: -0.2rem;
      border: 0.1rem dashed ${Vars.FocusColor};
      pointer-events: none;
    }
  }

  &:active {
    outline: 0;
  }

  &::-moz-focus-inner {
    border: none;
  }
`;

const FONT_SIZE = [
  Vars.FontSizeTitle,
  Vars.FontSizeH1,
  Vars.FontSizeH2,
  Vars.FontSizeH3,
  Vars.FontSizeH4
];
const LINE_HEIGHT = [
  Vars.LineHeightTitle,
  Vars.LineHeightH1,
  Vars.LineHeightH2,
  Vars.LineHeightH3,
  Vars.LineHeightH4
];

export const heading = (level: 0 | 1 | 2 | 3 | 4) => css`
  display: block;
  font-size: ${FONT_SIZE[level]};
  line-height: ${LINE_HEIGHT[level]};
  font-weight: ${level <= 1 ? 900 : level <= 4 ? 700 : 400};
`;

interface ButtonOptions {
  kind?: 'positive' | 'negative' | 'neutral' | 'brand';
  size?: 'small' | 'medium' | 'large' | 'xlarge';
}

export const button = ({
  kind = 'neutral',
  size = 'medium'
}: ButtonOptions = {}) => css`
  ${palette('base')};
  ${kind === 'positive'
    ? css`
        --background-color: ${Vars.PositiveColor};
        --background-color-hover: ${Vars.PositiveHoverColor};
        --background-color-active: ${Vars.PositiveActiveColor};
      `
    : kind === 'negative'
    ? css`
        --background-color: ${Vars.NegativeColor};
        --background-color-hover: ${Vars.NegativeHoverColor};
        --background-color-active: ${Vars.NegativeActiveColor};
      `
    : kind === 'brand'
    ? css`
        --background-color: ${Vars.BrandPrimaryColor};
        --background-color-hover: ${Vars.BrandPrimaryHoverColor};
        --background-color-active: ${Vars.BrandPrimaryActiveColor};
      `
    : css`
        --background-color: transparent;
        --background-color-hover: transparent;
        --background-color-active: ${Vars.Tint200};
      `}

  display: inline-block;
  position: relative;
  text-align: center;
  height: ${size === 'small'
    ? 3.2
    : size === 'medium'
    ? 3.6
    : size === 'large'
    ? 4.8
    : 6.4}rem;
  padding: 0
    ${size === 'small'
      ? 1.4
      : size === 'medium'
      ? 1.6
      : size === 'large'
      ? 2.4
      : 3.6}rem;
  line-height: ${size === 'small'
    ? 3
    : size === 'medium'
    ? 3.4
    : size === 'large'
    ? 4.6
    : 6.2}rem;
  font-size: ${size === 'xlarge'
    ? Vars.FontSizeH3
    : size === 'small'
    ? Vars.FontSizeInformation
    : Vars.FontSizeBody};
  font-weight: bold;
  white-space: nowrap;
  text-shadow: none;
  background-color: var(--background-color);
  cursor: pointer;
  transition: 0.25s background-color ease, 0.25s box-shadow ease;
  ${kind === 'neutral'
    ? css`
        box-shadow: inset 0 0 0 0.1rem ${Vars.Tint500};
      `
    : css`
        box-shadow: inset 0 -0.2rem 0 ${Vars.Shade400};
      `}

  &:hover {
    color: var(--text-color);
    background-color: var(--background-color-hover);
  }

  &:active {
    background-color: var(--background-color-active);
    box-shadow: inset 0 0 0 0.1rem var(--border-color-active);
  }

  &:focus {
    outline: 0;
  }

  &:focus::after {
    content: '';
    display: block;
    position: absolute;
    top: -0.2rem;
    bottom: -0.2rem;
    left: -0.2rem;
    right: -0.2rem;
    border: 0.1rem dashed ${base.focusColor};
    pointer-events: none;
  }

  &::-moz-focus-inner {
    border: none;
  }
`;

export const link = css`
  font-size: inherit;
  line-height: inherit;
  font-weight: bold;
  color: ${Vars.PositiveColor};
  cursor: pointer;
  transition: 0.25s color ease-in-out;

  &:hover {
    color: ${Vars.PositiveHoverColor};
  }
`;
